<script setup lang="ts">
import useAbsoluteUrl from '~/composables/useAbsoluteUrl';
import { useSiteStore } from '~/stores/site.store';
import { dtScripts } from '~/assets/data/dt-scripts';
const { getAbsoluteUrl } = useAbsoluteUrl();
const route = useRoute();
const siteStore = useSiteStore();

const runtimeConfig = useRuntimeConfig();

const loading = ref<boolean>(true);

onMounted(async () => {
  // fetches
  loading.value = false;
});

watchEffect(() =>
  useHead({
    link: [
      {
        rel: 'canonical',
        href: getAbsoluteUrl(route.path),
      },
    ],
  })
);

onMounted(() => {
  const dtEnabled = runtimeConfig?.public?.enableDt.toString() === 'true';
  const script = dtEnabled
    ? [
        {
          src: dtScripts[siteStore.getEnvironmentRegionCode],
          async: true,
          defer: true,
        },
      ]
    : [];
  useHead({
    script,
  });
});
</script>
<template>
  <div>
    <user-interface-fullscreen-loader v-if="loading" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
type ButtonGroups =
  | 'primary'
  | 'primary-gold'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-icon'
  | 'link'
  | 'link-secondary';
type PositionOptions = 'left' | 'right'; // Top and Bottom opts available if needed
type SizeOption =
  | 'small'
  | 'medium'
  | 'icon'
  | 'icon-sm'
  | 'icon-sm-round'
  | 'icon-md'
  | 'icon-lg';
interface ButtonProps {
  class?: string;
  iconClass?: string;
  group?: ButtonGroups;
  square?: boolean;
  background?: string;
  rounded?: boolean;
  share?: boolean;
  icon?: string;
  iconPos?: PositionOptions;
  size?: SizeOption;
  preventActive?: boolean;
  ariaLabel?: string;
}
const props = withDefaults(defineProps<ButtonProps>(), {
  class: '',
  group: 'primary',
  background: '',
  square: false,
  rounded: false,
  share: false,
  icon: '',
  iconPos: 'left',
  preventActive: false,
  ariaLabel: 'button',
});

const emits = defineEmits(['click']);

function determineBackground() {
  // Determine Classes according to button Group
  switch (props.group) {
    case 'primary':
      return `button--primary`;
    case 'primary-gold':
      return `button--primary-gold`;
    case 'secondary':
      return `button--secondary`;
    case 'tertiary':
      return `button--tertiary`;
    case 'tertiary-icon':
      return `button--tertiary-icon`;
    case 'link':
      return `button--link`;
    case 'link-secondary':
      return `button--link-secondary`;
    default:
      break;
  }
}

function determineSize() {
  // Determine button sizes
  switch (props.size) {
    case 'small':
      return 'button--small';
    case 'medium':
      return 'button--medium';
    case 'icon':
      return 'button--icon';
    case 'icon-sm':
      return 'button--icon-sm';
    case 'icon-md':
      return 'button--icon-md';
    case 'icon-lg':
      return 'button--icon-lg';
    case 'icon-sm-round':
      return 'button--icon-sm-round';
    default:
      break;
  }
}

function generateClass(): string {
  const background = determineBackground();
  const size = determineSize();
  const cursor = 'hover:cursor-pointer';
  const rounded = props.rounded ? 'rounded-full' : 'rounded-md';
  const prevent = props.preventActive ? 'prevent-active' : '';
  const classes = [props.class, background, cursor, size, rounded, prevent];
  const icon = props.icon ? props.icon : undefined;
  const iconPos = props.iconPos ? props.iconPos : 'left';
  return classes.join(' ');
}

function buttonClick(event: any) {
  emits('click', event);
}
</script>
<template>
  <Button
    :class="generateClass()"
    @click="buttonClick($event)"
    :icon="icon"
    :iconPos="iconPos"
    :iconClass="iconClass"
    role="button"
    :aria-label="ariaLabel"
  >
    <slot />
  </Button>
</template>

<style lang="scss">
/* TODO: make color and gradient vars dynamic and pull from base styles */
/* TODO: add social share styles */
span.pi.pi-check.p-button-icon {
  font-size: 24px;
  width: 24px;
  max-height: 24px;
}
.p-button[class*='icon'] > svg {
  margin: 0 auto;
}
.p-button {
  overflow: unset !important;
  text-align: center;
  font-size: 16px;
  height: 40px;
  line-height: 1.5;
  padding: 9.5px 16px;
  letter-spacing: normal;
  font-weight: bold;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  transition: background-color 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
  &:enabled:hover {
    background-color: inherit;
    border: inherit;
    background: inherit;
    color: inherit;
  }
  &:disabled {
    background-color: #dae0ed;
    cursor: not-allowed;
    color: #828da4;
    background-image: none;
    box-shadow: none;
  }

  &:focus:not(.prevent-active) {
    box-shadow: 0 0 0 0 transparent;
  }
  &.p-button-icon-only {
    &.button--icon-sm {
      padding: 0.1rem;
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}
.button {
  transition: ease all 150mss;
  &--medium {
    padding: 5.5px 12px;
    height: 32px;
  }
  &--small {
    padding: 3.5px 8px;
    height: 28px;
  }
  &--primary {
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(56, 121, 251, 0.5);
    background-image: linear-gradient(45deg, #2530ac 0%, #3879fb 100%);
    &.p-button:enabled:hover {
      box-shadow: 0 4px 12px 0 rgba(56, 121, 251, 0.5);
      background-image: linear-gradient(45deg, #2530ac 0%, #3879fb 100%);
      color: #ffffff;
    }
    &.p-button:enabled:focus:not(.prevent-active) {
      color: #ffffff;
      box-shadow: none;
      background-color: #3879fb;
      background-image: none;
    }
  }
  &--primary-gold {
    color: #ffffff;
    box-shadow: none;
    background: linear-gradient(45deg, #d87700 0%, #ffc018);
    &.p-button:enabled:hover {
      box-shadow: none;
      color: #ffffff;
      background: linear-gradient(45deg, #d87700 0%, #ffc018);
    }
    &.p-button:enabled:focus:not(.prevent-active) {
      color: #ffffff;
      box-shadow: none;
      background: linear-gradient(45deg, #d87700 0%, #ffc018);
    }
  }
  &--secondary {
    color: #ffffff;
    background-color: #383f4e;
    &.p-button:enabled:hover {
      color: #ffffff;
      background-color: #1d2129;
    }
    &.p-button:enabled:focus:not(.prevent-active) {
      color: #ffffff;
      background-color: #535e73;
    }
    &.p-button:disabled {
      color: #828da4;
      background-color: #dae0ed;
    }
  }
  &--tertiary {
    color: #3e384e;
    border: none;
    background-color: #fff;
    &.p-button:enabled:hover {
      background-color: #eceff6;
    }
    &.p-button:enabled:focus:not(.prevent-active) {
      background-color: #eceff6;
    }
    &-icon {
      background-color: #fff;
      color: #000;
      width: 32px;
      height: 32px;
    }
    &.icon-btn--bg {
      border-radius: 6px;
      background-color: #eceff6;
      .dark & {
        border-radius: 6px;
        background-color: #121417;
      }
    }
  }
  &--icon {
    &-lg {
      width: 40px;
      max-width: 40px;
      padding: 0 0.4rem;
      span.pi.pi-check.p-button-icon,
      svg {
        font-size: 24px;
        width: 24px;
        max-height: 24px;
      }
    }
    &-sm {
      padding: 0.1rem;
      height: 1.75rem;
      width: 1.75rem;
      span.pi.pi-check.p-button-icon,
      svg {
        font-size: 16px;
        width: 16px;
        max-height: 16px;
      }
      &-round {
        border-radius: 100px !important;
        width: 24px !important;
        height: 24px !important;
        padding: 0 !important;
      }
    }
    &-md,
    .p-button.p-button-icon-only {
      padding: 0.1rem !important;
      height: 2rem !important;
      width: 2rem;
      aspect-ratio: 1 / 1;
      span.pi.pi-check.p-button-icon,
      svg {
        font-size: 20px;
        width: 20px;
        max-height: 20px;
      }
    }
  }
  &--link {
    color: #2f2b3b;
    //&.p-button:enabled:hover {
    //  color: #ffffff;
    //  background-color: #3879fb;
    //  box-shadow: 0 4px 12px 0 rgba(56, 121, 251, 0.5);
    //  background-image: linear-gradient(71deg, #2530ac 0%, #3879fb 100%);
    //}
    //&.p-button:enabled:focus:not(.prevent-active) {
    //  color: #ffffff;
    //  box-shadow: none;
    //  background-color: #3879fb;
    //  background-image: none;
    //}
  }
}
.dark {
  .p-button {
    background-color: transparent;
    &:disabled {
      background-color: #2b303b;
      cursor: not-allowed;
      color: #828da4 !important;
    }
  }
  .button {
    &--link {
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--secondary {
      color: #ffffff;
      background-color: #383f4e;
    }
    &--tertiary {
      color: #ffffff;
      border: none;
      background-color: #131217;
      &.p-button:enabled:hover {
        background-color: #2b303b;
        color: #fff;
      }
      &.p-button:enabled:focus:not(.prevent-active) {
        background-color: #2b303b;
      }
      &-icon {
        background-color: #201d29;
        color: #fff;
        a .pi {
          font-size: 24px;
          width: 24px;
          max-height: 24px;
        }
      }
    }
    &--icon {
      &-lg {
        width: 40px;
        max-width: 40px;
        padding: 0 0.4rem;
        span.pi.pi-check.p-button-icon,
        svg {
          font-size: 24px;
          width: 24px;
          max-height: 24px;
        }
      }
      &-sm {
        padding: 0.1rem;
        height: 1.75rem;
        width: 1.75rem;
        span.pi.pi-check.p-button-icon,
        svg {
          font-size: 16px;
          width: 16px;
          max-height: 16px;
        }
      }
      &-md {
        padding: 0.1rem;
        height: 2rem;
        width: 2rem;
        span.pi.pi-check.p-button-icon,
        svg {
          font-size: 20px;
          width: 20px;
          max-height: 20px;
        }
      }
    }
    &--link {
      color: #2f2b3b;
      //&.p-button:enabled:hover {
      //  box-shadow: 0 4px 12px 0 rgba(56, 121, 251, 0.5);
      //  color: #ffffff;
      //  background-image: linear-gradient(71deg, #2530ac 0%, #3879fb 100%);
      //}
      //&.p-button:enabled:focus:not(.prevent-active) {
      //  background-color: #131217;
      //}
    }
  }
}
@media (max-width: 380px) {
  .user-actions--wrap {
    .account {
      .button--medium {
        font-size: 12px;
        padding: 2px;
        height: 22px;
      }
    }
    & .button--icon-md,
    .dark &.button--icon-md {
      height: 22px;
    }
  }
}
</style>

import { defineNuxtPlugin } from '#imports'

        export default defineNuxtPlugin(async (nuxtApp) => {
          if (import.meta.client) {
            const vueform = (await import('@vueform/vueform')).vueform
            const vueformConfig = (await import('/home/vsts/work/1/s/vueform.config.ts')).default
            
            nuxtApp.vueApp.use(vueform, vueformConfig)
          }
        })
        
<script setup lang="ts">
import ThemedImage from '~/components/design-system/themed-image.vue';

type LoaderSize = 'normal' | 'small';

interface ILoaderProps {
  fullScreen?: boolean;
  container?: boolean;
  standalone?: boolean;
  noBox?: boolean;
  size?: LoaderSize;
}

interface ILoaderValues {
  width: string;
  height: string;
  borderRadius: string;
  bgImage: string;
  keymoves: string[];
  class: string;
}

const props = withDefaults(defineProps<ILoaderProps>(), {
  fullScreen: false,
  container: false,
  standalone: true,
  noBox: true,
  size: 'normal',
});

const positioning = computed(() => {
  if (props.fullScreen)
    return 'fixed top-0 left-0 w-screen h-screen z-50 bg-light-900/80 dark:bg-dark-900/80';
  if (props.container)
    return 'absolute top-0 left-0 w-full h-full z-50 bg-light-900/80 dark:bg-dark-900/80';
  if (props.standalone) return 'relative top-0 left-0 container mx-auto h-90';
  if (props.noBox) return 'relative top-0 left-0 container mx-auto h-20';
  return '';
});

/** Dynamic Scaling
const loaderValues = reactive({
  width: '',
  height: '',
  borderRadius: '',
  bgImage: '',
  keymoves: [],
  class: '',
});

onBeforeMount(() => {
  switch (props.size) {
    case 'normal':
      setNormalLoader();
      break;
    case 'small':
      setSmallLoader();
      break;
    default:
      setNormalLoader();
      break;
  }
});

function setNormalLoader() {
  loaderValues.height = '54px';
  loaderValues.width = '54px';
  loaderValues.borderRadius = '8px';
  loaderValues.bgImage =
    'radial-gradient(circle 6px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 6px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 6px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 6px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 6px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 6px, #ffffff 100%, transparent 0)';
  loaderValues.keymoves[0] =
    '-12px -15px, -12px 0px, -12px 15px, 12px -15px, 12px 0px, 12px 15px';
  loaderValues.keymoves[1] =
    '0px -34px, -12px -34px, 14px 14px, 34px -12px, -14px -14px, 34px 12px';
  loaderValues.keymoves[2] =
    '0px -34px, -12px -34px, 14px 14px, 0px 0px, -14px -14px, 34px 12px';
  loaderValues.keymoves[3] =
    '0px -34px, -14px -14px, -14px 14px, 34px -15px, 14px -14px, 14px 14px';
  loaderValues.keymoves[4] =
    '0px -34px, -14px -14px, -14px 14px, 0px 0px, 14px -14px, 14px 14px';
  loaderValues.keymoves[5] =
    '-14px 0px, -14px -16px, -14px 16px, 14px 0px, 14px -16px, 14px 16px';
}

function setSmallLoader() {
  loaderValues.height = '22px';
  loaderValues.width = '22px';
  loaderValues.borderRadius = '4px';
  loaderValues.class = 'mb-2';
  loaderValues.bgImage =
    'radial-gradient(circle 2.5px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 2.5px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 2.5px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 2.5px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 2.5px, #ffffff 100%, transparent 0), \
    radial-gradient(circle 2.5px, #ffffff 100%, transparent 0)';
  loaderValues.keymoves[0] =
    '0px -34px, -12px -34px, 0px 0px, 34px -12px, 0px 0px,  34px 12px';
  loaderValues.keymoves[1] =
    '-6px -6px, -3px -33px, 0px -33px, 33px -3px, 36px 0px, 6px 6px';
  loaderValues.keymoves[2] =
    '-6px -6px, -3px -33px, 0px -33px, 33px -3px, 0px 0px, 6px 6px';
  loaderValues.keymoves[3] =
    '0px -34px, -6px -6px, -6px 6px, 64px -15px, 6px -6px, 6px 6px';
  loaderValues.keymoves[4] =
    ' 0px 0px, -6px -6px, -6px 6px, 55px -6px, 6px -6px, 6px 6px';
  loaderValues.keymoves[5] =
    '-5px -6px, -5px 0px, -5px 6px, 5px -6px, 5px 0px, 5px 6px';
}
*/
const logoClasses =
  'w-16rem cursor-pointer bg-no-repeat bg-contain md:bg-center xs:mt-1 h-max-content object-cover mb-4';
</script>

<template>
  <div>
    <div
      class="flex justify-content-center align-items-center"
      :class="positioning"
    >
      <div class="absolute flex flex-column align-items-center">
        <ThemedImage
          v-if="props.fullScreen"
          :image-class="logoClasses"
          :dark="
            useImagePath(
              '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/JPCLogo_Glow.png'
            )
          "
          :light="
            useImagePath(
              '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpc_logo_light.svg'
            )
          "
        />
        <!--      <span class="loader"></span>-->
        <svg
          id="eIkixLWChHr1"
          :key="positioning"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72"
          shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision"
        >
          <g id="eIkixLWChHr2_tr" transform="translate(36,36) rotate(0)">
            <g transform="translate(-36,-36)">
              <rect
                width="54"
                height="54"
                rx="7.2"
                ry="7.2"
                transform="translate(9 9)"
                fill="#42abff"
              />
              <circle
                id="eIkixLWChHr4"
                r="5.4"
                transform="translate(36 36)"
                opacity="0"
                fill="#fff"
              />
              <g
                id="eIkixLWChHr5_to"
                transform="translate(36.000001,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr6_to"
                transform="translate(36.000001,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr7_to"
                transform="translate(36.000001,35.999998)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,0.000002)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr8_to"
                transform="translate(35.999998,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr9_to"
                transform="translate(35.999998,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr10_to"
                transform="translate(35.999998,35.999998)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,0.000002)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>

        <!-- :class="loaderValues.class" -->
      </div>
    </div>
  </div>
</template>

<!-- Dynamic scaling styles`
  <style>
.loader {
  width: v-bind('loaderValues.width');
  height: v-bind('loaderValues.height');
  position: relative;
  border-radius: v-bind('loaderValues.borderRadius');
  background-color: #42abff;
  background-image: v-bind('loaderValues.bgImage');
  background-repeat: no-repeat;
  animation: move 5s linear infinite, rotate 1.25s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes move {
  0%,
  16% {
    background-position: v-bind('loaderValues.keymoves[0]');
  }
  17%,
  33% {
    background-position: v-bind('loaderValues.keymoves[1]');
  }
  34%,
  50% {
    background-position: v-bind('loaderValues.keymoves[2]');
  }
  51%,
  67% {
    background-position: v-bind('loaderValues.keymoves[3]');
  }
  68%,
  84% {
    background-position: v-bind('loaderValues.keymoves[4]');
  }
  85%,
  100% {
    background-position: v-bind('loaderValues.keymoves[5]');
  }
}
</style>-->

<style scoped>
#eIkixLWChHr1 {
  width: 60px;
  height: 60px;
}

#eIkixLWChHr2_tr {
  animation: eIkixLWChHr2_tr__tr 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr2_tr__tr {
  0% {
    transform: translate(36px, 36px) rotate(0deg);
  }
  33.333333% {
    transform: translate(36px, 36px) rotate(360deg);
  }
  66.666667% {
    transform: translate(36px, 36px) rotate(720deg);
  }
  100% {
    transform: translate(36px, 36px) rotate(1080deg);
  }
}
#eIkixLWChHr4 {
  animation: eIkixLWChHr4_c_o 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr4_c_o {
  0% {
    opacity: 0;
  }
  16.666667% {
    opacity: 0;
  }
  33.333333% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  66.666667% {
    opacity: 1;
  }
  83.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eIkixLWChHr5_to {
  animation: eIkixLWChHr5_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr5_to__to {
  0% {
    transform: translate(36.000001px, 36.000001px);
  }
  16.666667% {
    transform: translate(49.500001px, 49.500001px);
  }
  33.333333% {
    transform: translate(49.500001px, 49.500001px);
  }
  50% {
    transform: translate(49.500001px, 22.500001px);
  }
  66.666667% {
    transform: translate(49.500001px, 22.500001px);
  }
  83.333333% {
    transform: translate(49.500001px, 22.500001px);
  }
  100% {
    transform: translate(36.000001px, 36.000001px);
  }
}
#eIkixLWChHr6_to {
  animation: eIkixLWChHr6_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr6_to__to {
  0% {
    transform: translate(36.000001px, 36.000001px);
  }
  16.666667% {
    transform: translate(49.500001px, 49.500001px);
  }
  33.333333% {
    transform: translate(49.500001px, 49.500001px);
  }
  50% {
    transform: translate(49.500001px, 49.500001px);
  }
  66.666667% {
    transform: translate(49.500001px, 49.500001px);
  }
  83.333333% {
    transform: translate(49.500001px, 36.000001px);
  }
  100% {
    transform: translate(36.000001px, 36.000001px);
  }
}
#eIkixLWChHr7_to {
  animation: eIkixLWChHr7_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr7_to__to {
  0% {
    transform: translate(36.000001px, 35.999998px);
  }
  16.666667% {
    transform: translate(49.500001px, 49.499998px);
  }
  33.333333% {
    transform: translate(49.500001px, 49.499998px);
  }
  50% {
    transform: translate(49.500001px, 49.499998px);
  }
  66.666667% {
    transform: translate(49.500001px, 49.499998px);
  }
  83.333333% {
    transform: translate(49.500001px, 49.499998px);
  }
  100% {
    transform: translate(36.000001px, 35.999998px);
  }
}
#eIkixLWChHr8_to {
  animation: eIkixLWChHr8_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr8_to__to {
  0% {
    transform: translate(35.999998px, 36.000001px);
  }
  16.666667% {
    transform: translate(22.499998px, 22.500001px);
  }
  33.333333% {
    transform: translate(22.499998px, 22.500001px);
  }
  50% {
    transform: translate(22.499998px, 22.500001px);
  }
  66.666667% {
    transform: translate(22.499998px, 22.500001px);
  }
  83.333333% {
    transform: translate(22.499998px, 36.000001px);
  }
  100% {
    transform: translate(35.999998px, 36.000001px);
  }
}
#eIkixLWChHr9_to {
  animation: eIkixLWChHr9_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr9_to__to {
  0% {
    transform: translate(35.999998px, 36.000001px);
  }
  16.666667% {
    transform: translate(22.499998px, 22.500001px);
  }
  33.333333% {
    transform: translate(22.499998px, 22.500001px);
  }
  50% {
    transform: translate(22.499998px, 22.500001px);
  }
  66.666667% {
    transform: translate(22.499998px, 22.500001px);
  }
  83.333333% {
    transform: translate(22.499998px, 22.500001px);
  }
  100% {
    transform: translate(35.999998px, 36.000001px);
  }
}
#eIkixLWChHr10_to {
  animation: eIkixLWChHr10_to__to 5000ms linear infinite normal forwards;
}
@keyframes eIkixLWChHr10_to__to {
  0% {
    transform: translate(35.999998px, 35.999998px);
  }
  16.666667% {
    transform: translate(22.499998px, 22.499998px);
  }
  33.333333% {
    transform: translate(22.499998px, 22.499998px);
  }
  50% {
    transform: translate(22.499998px, 49.499998px);
  }
  66.666667% {
    transform: translate(22.499998px, 49.499998px);
  }
  83.333333% {
    transform: translate(22.499998px, 49.499998px);
  }
  100% {
    transform: translate(35.999998px, 35.999998px);
  }
}

.loader {
  width: 54px;
  height: 54px;
  position: relative;
  border-radius: 8px;
  background-color: #42abff;
  background-image: radial-gradient(circle 6px, #ffffff 100%, transparent 0),
    radial-gradient(circle 6px, #ffffff 100%, transparent 0),
    radial-gradient(circle 6px, #ffffff 100%, transparent 0),
    radial-gradient(circle 6px, #ffffff 100%, transparent 0),
    radial-gradient(circle 6px, #ffffff 100%, transparent 0),
    radial-gradient(circle 6px, #ffffff 100%, transparent 0);
  background-repeat: no-repeat;
  animation: move 6s linear infinite, rotate 1.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes move {
  /*
  0%,
  10% {
    background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  }
  11%,
  100% {
    background-position: 14px 14px, -14px -14px, 14px 14px, 14px 14px,
      -14px -14px, -14px -14px;
  }
*/

  0%,
  15% {
    background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  }
  17%,
  33% {
    background-position: 14px 14px, -14px -14px, 14px 14px, 14px 14px,
      -14px -14px, -14px -14px;
  }
  34%,
  50% {
    background-position: 0px -34px, -12px -34px, 14px 14px, 0px 0px, -14px -14px,
      34px 12px;
  }
  51%,
  67% {
    background-position: 0px -34px, -14px -14px, -14px 14px, 34px -15px,
      14px -14px, 14px 14px;
  }
  68%,
  84% {
    background-position: 0px -34px, -14px -14px, -14px 14px, 0px 0px, 14px -14px,
      14px 14px;
  }
  85%,
  100% {
    background-position: -14px 0px, -14px -16px, -14px 16px, 14px 0px,
      14px -16px, 14px 16px;
  }
}
</style>

import { useAuthStore } from '~/stores/auth.store';
import useGetFavorites from '~/composables/useGetFavorites';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import { useStorageService } from '~/composables/services/useStorageService';
export default defineNuxtPlugin({
  name: 'init-client',
  hooks: {
    async 'app:created'() {
      const runtimeConfig = useRuntimeConfig();
      const auth = useAuthStore();
      const site = useSiteStore();

      const {
        $localeService,
        $configService,
        $sitemapService,
        $storageService,
      } = useServices();

      const host = document.location?.host;
      const ipRegex = new RegExp('(([0-9]?[0-9]?[0-9])(.|$)){4}');
      const countryCode =
        String(host)?.startsWith('localhost') ||
        String(host)?.endsWith('.dev') ||
        ipRegex.test(String(host)?.split(':')[0])
          ? runtimeConfig.public.defaultCountry
          : host?.slice(-2) || 'ZA';

      site.setRegion(String(countryCode));

      const localeCookie = $storageService.getCookie({ key: 'locale' });
      await $sitemapService.fetchSitemap(site.regionCode);
      await $configService.fetchConfig(site.regionCode);
      await $configService.fetchRedirects(site.regionCode);
      await $localeService.fetchLocale(localeCookie);

      auth.initAuth();

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/image-worker.js', { scope: './' })
          .catch(function (err) {
            console.error('Service Worker Failed to Register', err);
          });
      }

      window.navigate = (path: string) => {
        navigateTo(path);
      };
      window.openModal = (modal: string) => {
        switch (modal.toLocaleLowerCase()) {
          case 'login':
            if (!auth.loggedIn) {
              site.activateModal(modal);
            }
            break;

          case 'register':
            if (!auth.loggedIn) {
              site.activateModal(modal);
            }
            break;

          case 'reset-password':
            if (auth.loggedIn) {
              auth.setAccountModalPage('password-management');
            } else {
              site.activateModal('passwordResetStepOne');
            }
            break;

          case 'deposit':
            if (auth.loggedIn) {
              auth.setAccountModalPage('deposit');
            } else {
              site.activateModal('login');
            }
            break;

          case 'withdrawal':
            if (auth.loggedIn) {
              auth.setAccountModalPage('withdrawal');
            } else {
              site.activateModal('login');
            }
            break;

          default:
            break;
        }
      };
      window.displayLiveChat = () => {
        site.toggleFeature({ feature: 'displayLiveChat' });
      };
    },
    'app:mounted'() {
      const { $walletService, $authService, $toastService } = useServices();
      const site = useSiteStore();
      if (site.getFeatureFlags.get('website.toastmessages')) {
        $toastService.toastService.fetchToasts();
      }
      const auth = useAuthStore();
      if (auth.loggedIn) {
        $authService
          .refreshToken()
          .then(async () => {
            await $walletService.fetchBalances();
            await $authService.getUserExtended();
          })
          .catch((e: any) => {
            console.error({ e });
          });
      }
    },
  },
});

import { default as _91game_93_46clienti7c4z1nMJsMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category]/[game].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/vsts/work/1/s/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexu2Behuxh8YMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category]/index.vue?macro=true";
import { default as _91category_93TFv9kQpq4mMeta } from "/home/vsts/work/1/s/pages/[contentType]/[category].vue?macro=true";
import { default as indexVljXzLykFtMeta } from "/home/vsts/work/1/s/pages/[contentType]/index.vue?macro=true";
import { default as _91provider_93TnjMx1FOtiMeta } from "/home/vsts/work/1/s/pages/[contentType]/providers/[provider].vue?macro=true";
import { default as indexzxGJcsPKWaMeta } from "/home/vsts/work/1/s/pages/[contentType]/providers/index.vue?macro=true";
import { default as _91contentType_93759yoqNeMyMeta } from "/home/vsts/work/1/s/pages/[contentType].vue?macro=true";
import { default as _404UvIQCsXWa8Meta } from "/home/vsts/work/1/s/pages/404.vue?macro=true";
import { default as _91post_93WFoHdEKyVjMeta } from "/home/vsts/work/1/s/pages/bigcitylife/[categories]/[post].vue?macro=true";
import { default as indexedhhES2Ck8Meta } from "/home/vsts/work/1/s/pages/bigcitylife/[categories]/index.vue?macro=true";
import { default as indexboHbsLSx2lMeta } from "/home/vsts/work/1/s/pages/bigcitylife/index.vue?macro=true";
import { default as healthgO3FPzNA7uMeta } from "/home/vsts/work/1/s/pages/health.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as indexnv1kwthUy0Meta } from "/home/vsts/work/1/s/pages/promotions/[promotion]/index.vue?macro=true";
import { default as index1zF81j02A3Meta } from "/home/vsts/work/1/s/pages/promotions/index.vue?macro=true";
import { default as component_45stubFAPXzSYSvjMeta } from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFAPXzSYSvj } from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: _91contentType_93759yoqNeMyMeta?.name,
    path: "/:contentType()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType].vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93TFv9kQpq4mMeta?.name,
    path: ":category()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/[category].vue").then(m => m.default || m),
    children: [
  {
    name: "contentType-category-game",
    path: ":game()",
    component: () => createClientPage(() => import("/home/vsts/work/1/s/pages/[contentType]/[category]/[game].client.vue").then(m => m.default || m))
  },
  {
    name: "contentType-category",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/[category]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contentType",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/index.vue").then(m => m.default || m)
  },
  {
    name: "contentType-providers-provider",
    path: "providers/:provider()",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: "contentType-providers",
    path: "providers",
    component: () => import("/home/vsts/work/1/s/pages/[contentType]/providers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/home/vsts/work/1/s/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "bigcitylife-categories-post",
    path: "/bigcitylife/:categories()/:post()",
    component: () => import("/home/vsts/work/1/s/pages/bigcitylife/[categories]/[post].vue").then(m => m.default || m)
  },
  {
    name: "bigcitylife-categories",
    path: "/bigcitylife/:categories()",
    component: () => import("/home/vsts/work/1/s/pages/bigcitylife/[categories]/index.vue").then(m => m.default || m)
  },
  {
    name: "bigcitylife",
    path: "/bigcitylife",
    component: () => import("/home/vsts/work/1/s/pages/bigcitylife/index.vue").then(m => m.default || m)
  },
  {
    name: "health",
    path: "/health",
    meta: healthgO3FPzNA7uMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/health.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "promotions-promotion",
    path: "/promotions/:promotion()",
    component: () => import("/home/vsts/work/1/s/pages/promotions/[promotion]/index.vue").then(m => m.default || m)
  },
  {
    name: "promotions",
    path: "/promotions",
    component: () => import("/home/vsts/work/1/s/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/crashgames/crashgames",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/crashgames/crashgames/aviator",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/aviator/aviator",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/aviator/aviator/aviator",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/quickgames/quickgames",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/betgames/betgames",
    component: component_45stubFAPXzSYSvj
  },
  {
    name: component_45stubFAPXzSYSvjMeta?.name,
    path: "/home",
    component: component_45stubFAPXzSYSvj
  }
]
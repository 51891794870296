import type { IMarketingSessionAddRequest } from '~/interfaces/dto/marketing/request';
import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useLocaleStore } from '~/stores/locale.store';

export default defineNuxtPlugin({
  name: 'marketing-optin-service',
  enforce: 'post',
  dependsOn: ['init-client'],
  hooks: {
    async 'app:mounted'() {
      const auth = useAuthStore();
      const site = useSiteStore();
      const locale = useLocaleStore();
      const route = useRoute();
      const { query } = route;

      const $storageService = useStorageService();
      const $marketingService = useMarketingService();

      const requestInfo = await $marketingService.fetchDeviceInfo();
      const { deviceInfo, IpAddress } = requestInfo;

      if (auth.loggedIn && !!query?.optin) {
        if (!!query?.optin) {
          await $marketingService
            .setMarketingOptIn({
              vendorAccountID: $storageService.getLocalStorage({
                key: 'auth:userId',
              }),
              optinName: String(query?.optin),
              source: String(query?.source),
              countryCode: site.getRegionCode,
            })
            .then((d) => {
              if (typeof d === 'boolean' && d) {
                site.toggleSiteNotice({
                  heading: locale.localeKey('marketing-opt-in-heading'),
                  message: locale.localeKey('marketing-opt-in-message'),
                  severity: 'success',
                  enabled: true,
                  timeout: 5000,
                });
              }
            });
        }
      } else if (!auth.loggedIn && !!query?.optin) {
        $storageService.setLocalStorage({
          key: 'optinSession',
          data: JSON.stringify({
            optin: query.optin,
            source: query.source,
          }),
        });

        watch(
          () => auth.loggedIn,
          async (value) => {
            if (value) {
              const optin = $storageService.getLocalStorage({
                key: 'optinSession',
              });
              await $marketingService
                .setMarketingOptIn({
                  vendorAccountID: $storageService.getLocalStorage({
                    key: 'auth:userId',
                  }),
                  optinName: String(optin?.optin),
                  source: String(optin?.source),
                  countryCode: site.getRegionCode,
                })
                .then((d) => {
                  if (typeof d === 'boolean' && d) {
                    site.toggleSiteNotice({
                      heading: locale.localeKey('marketing-opt-in-heading'),
                      message: locale.localeKey('marketing-opt-in-message'),
                      severity: 'success',
                      enabled: true,
                      timeout: 5000,
                    });
                    $storageService.removeLocalStorage({
                      key: 'optinSession',
                    });
                  }
                });
            }
          }
        );
      }

      if (auth.isLoggedIn && !!sessionStorage.getItem('MkSession')) {
        const regionId: number = 13;
        const platformIds = {
          mobile: 1,
          tablet: 1,
          desktop: 2,
          ios: 3,
          android: 4,
          huawei: 5,
        };

        const appType =
          String(query?.appType) ||
          (deviceInfo.IsMobile || deviceInfo.IsTablet ? 'mobile' : 'desktop');

        const sessionData: IMarketingSessionAddRequest = {
          platformID: platformIds[appType.toLowerCase()],
          regionID: regionId,
          vendorAccountID: $storageService.getLocalStorage({
            key: 'auth:userId',
          }),
          dataFree: false,
          bannerTag: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
          queryParamString: $storageService.getCookie({
            key: 'OriginalQueryString',
          }),
          ipAddress: IpAddress,
          countryCode: site.getRegionCode,
          userAgent: navigator.userAgent,
          sessionTrackingToken: '',
          sessionMetaData: [
            {
              cookieName: 'REFERRERBTAGCOOKIE',
              cookieValue: $storageService.getCookie({
                key: 'REFERRERBTAGCOOKIE',
              }),
              cookieDomain: '',
            },
            {
              cookieName: 'MT',
              cookieValue: $storageService.getCookie({ key: 'MT' }),
              cookieDomain: '',
            },
            {
              cookieName: 'ST',
              cookieValue: $storageService.getCookie({ key: 'ST' }),
              cookieDomain: '',
            },
          ],
          ...deviceInfo,
        };

        await $marketingService.setMarketingSession(sessionData);
      }
    },
  },
});

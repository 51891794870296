import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import type { RouteLocationNormalized } from 'vue-router';
import { useServices } from '~/composables/useServices';
import { useLocaleStore } from '~/stores/locale.store';

export const useQueryHooks = (routeObj: RouteLocationNormalized) => {
  const { query } = routeObj;
  const queryStr = Object.keys(query);
  const settings = useSiteStore();
  const auth = useAuthStore();
  const locale = useLocaleStore();
  const { $toastService, $marketingService, $storageService, $authService } = useServices();
  
  setTimeout(() => {
    queryStr.forEach(async (qs) => {
      qs = qs.replace(/amp;/g, '');
      switch (qs) {
        case 'redirect':
          navigateTo(String(query?.redirect));
          break;
        case 'login':
          if (!auth.loggedIn) {
            settings.activateModal(qs);
          }
          break;
        case 'displayAccount':
        case 'displayaccount':
          if (auth.loggedIn) {
            auth.toggleAccountModal(true);
          }
          break;
        case 'register':
          if (!auth.loggedIn) {
            settings.activateModal(qs);
          }
          break;
        case 'reset-password':
          if (auth.loggedIn) {
            auth.setAccountModalPage('password-management');
          } else {
            settings.activateModal('passwordResetStepOne');
          }
          break;
        case 'deposit':
          if (auth.loggedIn) {
            auth.setAccountModalPage('deposit');
          }
          break;
        case 'withdrawal':
          if (auth.loggedIn) {
            auth.setAccountModalPage('withdrawal');
          }
          break;
        case 'live-chat':
          setTimeout(() => {
            settings.toggleFeature({ feature: 'displayLiveChat' });
          }, 1000);
          break;
        case 'toast':
          $toastService.toastService.generateToast(String(query['toast']));
          break;
        case 'optin':
          const optInName = String(query?.optin);
          await $marketingService
            .setMarketingOptIn({
              vendorAccountID: $storageService.getLocalStorage({
                key: 'auth:userId',
              }),
              optinName: optInName,
              source: String(query?.source || 'Web'),
              countryCode: settings.getRegionCode,
            })
            .then((d) => {
              settings.toggleSiteNotice({
                heading: locale.localeKey('marketing-opt-in-heading'),
                message: locale.localeKey('marketing-opt-in-message'),
                severity: 'success',
                enabled: true,
                timeout: 5000,
              });
            });
          break;
          case 'document-verification':
            if (auth.loggedIn) {
              auth.setAccountModalPage('document-verification');
            }
            break;
            case 'session':
              $authService.handleLoginSession(String(query['session']));
            break;
        default:
          break;
      }
    });
  }, 250);
};

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"}],"link":[{"rel":"icon","type":"image/png","href":"https://cms1.jpc.africa/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/JPC_Favicon.png"},{"rel":"preload","as":"image","href":"https://media.jpc.africa/medialibraries/Jackpotcity.co.za/content-pages/placeholders/JPC_GameTilePlaceHolder_500x500.jpg"},{"rel":"stylesheet","href":"https://cdn.jpc.africa/Global/css/jackpotcity/theme.min.css"},{"rel":"stylesheet","href":"https://cdn.jpc.africa/Global/css/jackpotcity/primeflex.min.css"},{"rel":"stylesheet","href":"https://cdn.jpc.africa/Global/css/jackpotcity/primeicons.min.css"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"class":"","style":"background: #121417;"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000
import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCalendar, LazyCheckbox, LazyDropdown, LazyInputMask, LazyInputSwitch, LazyInputText, LazyPassword, LazyRadioButton, LazyRating, LazyToggleButton, LazyButton, LazySpeedDial, LazyColumn, LazyDataTable, LazyAccordion, LazyAccordionTab, LazyCard, LazyTabView, LazyTabPanel, LazyOverlayPanel, LazySidebar, LazyMenubar, LazyToast, LazyBadge, LazyChip, LazySkeleton, LazyProgressBar, LazyTag } from '#components'
const lazyGlobalComponents = [
  ["Calendar", LazyCalendar],
["Checkbox", LazyCheckbox],
["Dropdown", LazyDropdown],
["InputMask", LazyInputMask],
["InputSwitch", LazyInputSwitch],
["InputText", LazyInputText],
["Password", LazyPassword],
["RadioButton", LazyRadioButton],
["Rating", LazyRating],
["ToggleButton", LazyToggleButton],
["Button", LazyButton],
["SpeedDial", LazySpeedDial],
["Column", LazyColumn],
["DataTable", LazyDataTable],
["Accordion", LazyAccordion],
["AccordionTab", LazyAccordionTab],
["Card", LazyCard],
["TabView", LazyTabView],
["TabPanel", LazyTabPanel],
["OverlayPanel", LazyOverlayPanel],
["Sidebar", LazySidebar],
["Menubar", LazyMenubar],
["Toast", LazyToast],
["Badge", LazyBadge],
["Chip", LazyChip],
["Skeleton", LazySkeleton],
["ProgressBar", LazyProgressBar],
["Tag", LazyTag],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

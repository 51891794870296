import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';

export default defineNuxtPlugin((NuxtApp) => {
  const $storageService = useStorageService();
  const $marketingService = useMarketingService();

  function SetQueryStringParams() {
    let websiteUrl = window.location.href;

    function removeURLParameter(url, parameter) {
      const urlparts = url.split('?');
      if (urlparts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlparts[1].split(/[&;]/g);
        for (let i = pars.length; i-- > 0; ) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    }

    websiteUrl = removeURLParameter(websiteUrl, 'mobileNumber');
    websiteUrl = removeURLParameter(websiteUrl, 'email');
    websiteUrl = removeURLParameter(websiteUrl, 'firstName');
    websiteUrl = removeURLParameter(websiteUrl, 'lastName');
    websiteUrl = removeURLParameter(websiteUrl, 'MobileNumber');
    websiteUrl = removeURLParameter(websiteUrl, 'Email');
    websiteUrl = removeURLParameter(websiteUrl, 'FirstName');
    websiteUrl = removeURLParameter(websiteUrl, 'LastName');
    const t = websiteUrl.split('?');
    t.length === 2
      ? $storageService.setCookie({
          key: 'OriginalQueryString',
          data: t[1],
          expiry: 7,
        })
      : $storageService.setCookie({
          key: 'OriginalQueryString',
          data: '/',
          expiry: 7,
        });
  }

  NuxtApp.hook('app:mounted', async () => {
    const route = useRoute();
    const currentBtag = $storageService.getCookie({ key: 'BTAGCOOKIE' });
    const originalQueryString = $storageService.getCookie({
      key: 'OriginalQueryString',
    });

    const masterToken = $storageService.getCookie({ key: 'MT' });
    const sessionToken = $storageService.getCookie({ key: 'ST' });

    const referrer =
      document.referrer.replace(/\?.*/gm, '') || window.location.origin;

    if (!currentBtag && !!route.query.btag) {
      $storageService.setCookie({
        key: 'BTAGCOOKIE',
        data: String(route.query.btag),
        expiry: 7,
      });
      $storageService.setCookie({
        key: 'REFERRERBTAGCOOKIE',
        data: String(route.query.btag),
      });
    } else if (!!currentBtag && !!route.query.btag) {
      $storageService.setCookie({
        key: 'REFERRERBTAGCOOKIE',
        data: String(route.query.btag),
      });
    }
    try {
      if (!originalQueryString) SetQueryStringParams();
      // await getOriginToken();
      if (!sessionToken || !masterToken)
        await $marketingService.fetchOriginTokens();
      await $marketingService.fetchBannerTags({ referrer });
    } catch (e) {
      console.error(e);
    }
  });

  return {
    provide: {
      setOriginalQueryString: SetQueryStringParams,
    },
  };
});

import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_i8AMfKeYnY from "/home/vsts/work/1/s/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_client_W86eSGFqyZ from "/home/vsts/work/1/s/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/home/vsts/work/1/s/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/home/vsts/work/1/s/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import vueformPlugin_kEcARWMiqX from "/home/vsts/work/1/s/.nuxt/vueformPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_init_client_US1MlgQlWm from "/home/vsts/work/1/s/plugins/01.init.client.ts";
import analytics_client_mtqVw3YRXk from "/home/vsts/work/1/s/plugins/analytics.client.ts";
import banner_tag_service_client_WAxOfHcaOk from "/home/vsts/work/1/s/plugins/banner-tag.service.client.ts";
import directives_8CcCirWtnE from "/home/vsts/work/1/s/plugins/directives.ts";
import global_middleware_QwjltJ6fek from "/home/vsts/work/1/s/plugins/global.middleware.ts";
import locale_service_jVfQb2yvjM from "/home/vsts/work/1/s/plugins/locale.service.ts";
import service_worker_message_handler_client_sqNcuVFacM from "/home/vsts/work/1/s/plugins/service-worker-message.handler.client.js";
import marketing_optin_service_client_F9cuZzQO4V from "/home/vsts/work/1/s/plugins/marketing-optin.service.client.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_i8AMfKeYnY,
  plugin_client_W86eSGFqyZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  vueformPlugin_kEcARWMiqX,
  chunk_reload_client_UciE0i6zes,
  _01_init_client_US1MlgQlWm,
  analytics_client_mtqVw3YRXk,
  banner_tag_service_client_WAxOfHcaOk,
  directives_8CcCirWtnE,
  global_middleware_QwjltJ6fek,
  locale_service_jVfQb2yvjM,
  service_worker_message_handler_client_sqNcuVFacM,
  marketing_optin_service_client_F9cuZzQO4V
]
import { useLocaleStore } from '~/stores/locale.store';

export default defineNuxtPlugin(async () => {
  const locale = useLocaleStore();

  return {
    provide: {
      t: locale.localeKey,
      locale,
    },
  };
});

<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import ThemedImage from '~/components/design-system/themed-image.vue';

const router = useRouter();
const imagesPath: Record<string, string> = {
  dark: useImagePath(
    '/medialibraries/Jackpotcity.co.za/content-pages/404_light.png'
  ),
  light: useImagePath(
    '/medialibraries/Jackpotcity.co.za/content-pages/404_dark.png'
  ),
};

function routeHomeAndClear() {
  clearError({ redirect: '/' });
}
</script>

<template>
  <div
    class="flex flex-column justify-content-between align-items-center mt-6 px-2 container"
  >
    <ThemedImage
      :dark="imagesPath.dark"
      :light="imagesPath.light"
      image-class="w-full md:w-90 mx-auto"
    />
    <div class="dark:text-light-50 text-dark-900">
      <div
        class="dark:text-white flex flex-column align-items-center text-center text-5xl font-normal line-height-1 pt-8"
      >
        <div class="font-semibold">
          {{ $t('jpc-page-not-found.title') }}
        </div>
        <div>
          {{ $t('jpc-page-not-found.subtitle') }}
        </div>
      </div>

      <div class="text-xl font-normal line-height-3">
        <p>{{ $t('jpc-page-not-found.description-title') }}</p>

        <ul>
          <li>{{ $t('jpc-page-not-found.description-bullet-one') }}</li>
          <li>{{ $t('jpc-page-not-found.description-bullet-two') }}</li>
        </ul>
      </div>

      <div class="p-6 flex justify-content-center">
        <Button @click="routeHomeAndClear()" class="mx-auto">
          {{ $t('back-button') }}
        </Button>
      </div>
    </div>
  </div>
</template>

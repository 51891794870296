<template>
  <div
    class="bg-light-200 dark:bg-dark-900 w-screen h-screen fixed top-0 right-0 flex flex-column justify-content-center align-items-center z-50"
  >
    <ThemedImage
      :image-class="logoClasses"
      :dark="
        useImagePath(
          '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/JPCLogo_Glow.png'
        )
      "
      :light="
        useImagePath(
          '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpc_logo_light.svg'
        )
      "
    />
    <GenericLoader :standalone="false" noBox />
  </div>
</template>
<script setup lang="ts">
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import ThemedImage from '~/components/design-system/themed-image.vue';
const logoClasses =
  'p-8 w-full md:p-0 md:w-72 cursor-pointer bg-no-repeat bg-contain md:bg-center xs:mt-1 object-cover';
</script>

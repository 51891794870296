import { useSiteStore } from '../stores/site.store';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useSiteStore();

  nuxtApp.vueApp.directive(`enabled`, {
    created(el: HTMLElement, binding, vnode, prevVnode) {
      const remove = document.createComment('  ');
      if (!config.getFeatureFlags.get(binding.value.toLowerCase())) {
        el.replaceWith(remove, el);
      }
    },
    mounted(el: HTMLElement, binding, vnode, prevVnode) {
      const remove = document.createComment('  ');
      if (!config.getFeatureFlags.get(binding.value.toLowerCase())) {
        el.replaceWith(remove, el);
      }
    },
    updated(el: HTMLElement, binding, vnode, prevVnode) {
      const remove = document.createComment('  ');
      if (!config.getFeatureFlags.get(binding.value.toLowerCase())) {
        el.replaceWith(remove, el);
      }
    },
  });
  const lookupFeature = (key: string): boolean => {
    return config.getFeatureFlags.get(key);
  };
  return {
    provide: {
      enabled: lookupFeature,
    },
  };
});
